import React from 'react'
import Layout from '../components/layout'
import EnterpriseArchitecture from '../components/categories/enterprise-architecture'
import Seo from '../components/seo'

const EnterpriseArchitecturePage = ({ location }) => {
  return (
    <Layout location={location}>
      <EnterpriseArchitecture />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Enterprise Architecture Freelancers | Codestaff'
  const description =
    'Hire the best Enterprise Architecture freelancers at Codestaff. Get the top 1% of Enterprise Architecture professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default EnterpriseArchitecturePage
